import React from 'react';
import Layout from '../../layouts/Project';
import ProjectIntro from '../../content/ProjectIntro';
import CapsAndTech from '../../content/CapsAndTech';
import Text from '../../content/Text';
import Caption from '../../content/ImageCaption';
import { StaticImage as Image } from 'gatsby-plugin-image';
import Images from '../../content/Images';
import Video from '../../content/Video';
import Press from '../../content/Press';
import Credits from '../../content/Credits';

export const frontmatter = {
  title: 'Traverse',
  projectType: 'Products',
  description:
    'TRAVERSE is a mobile platform for creating spatial audio experiences that makes listening 6 dimensional. It has featured at multiple festivals around the world including SXSW, Sonaar and Tribeca Film\u00A0Festival.',
  client: ['In partnership with Vrai Pictures & Bose'],

  release: '2019',
  capabilities: [
    'Product Development ',
    'Experience Design',
    'Software Engineering',
    'Real-Time ',
    'AR, VR'
  ],

  technology: [
    'Proprietary Spatial Audio Pipeline',
    'Swift',
    'Unity',
    'GLSL ',
    'MACH1'
  ],
  credits: {
    Partnership: 'Vrai Pictures / Bose',
    Engineering: 'Superbright',
    Production: 'Superbright',
    'Graphic Design': 'Combination Rule',
    'Sound Design': 'Antfood, Q Department, Mach 1'
  },
  order: 3
};

const clientLogos = [
  <Image
    src="../../images/work/traverse/vrai-pictures.png"
    alt="Vrai Pictures"
    placeholder="none"
  />,
  <Image
    src="../../images/work/traverse/bose.png"
    alt="Bose"
    placeholder="none"
  />
];
const Page = () => (
  <Layout title={frontmatter.title}>
    <ProjectIntro frontmatter={frontmatter} clientLogos={clientLogos} />{' '}
    <Images>
      <Image src="../../images/work/traverse/TRAVERSE_COVER.png" alt="Traverse" />
      <Image src="../../images/work/traverse/traverse-press-product1.png" alt="Traverse" />
    </Images>
    <CapsAndTech frontmatter={frontmatter} />
    <Text title="Opportunity" large split={1}>
      <p>
        In 2019 Bose released the first AR enabled headphones, their mission was
        to build relationships with the developer community and enable a new era
        of spatial audio applications and experiences that could be leveraged
        across different markets and future hardware.
      </p>
      <p>
        Prior to launch, Superbright in partnership with Vrai Pictures were
        commisioned by Bose to develop a spatial audio experience that leveraged
        6DOF Augmented Reality technology and create a completely new way of
        experiencing music & sound, one that allowed the listener to be present
        within the audio space as it was originally recorded.
      </p>
    </Text>
    <Images>
      
    <Image
        src="../../images/work/traverse/ELVIS_MEMPHIS.jpg"
        alt="Traverse"/>

      <Image src="../../images/work/traverse/CHAOS_ORDER.jpg" alt="Traverse" />

      <Image
        src="../../images/work/traverse/MARS_INSIGHT.jpg"
        alt="Traverse"
      />
      <Image
        src="../../images/work/traverse/INTO_LIGHT.jpg"
        alt="Traverse"
      />
    </Images>
    <Text title="Solution">
      <p>
        Superbright designed and developed a unique 6Dof Spatial Audio
        application that allows listeners to 'walk through' sound using an AR
        enabled mobile device guided by an intuitive 2D audio map. This was the
        inception point for the Traverse platform.
      </p>
      <p>
        The application works by combining sensor fusion data from the gyroscope
        on the headphones with the sensors on your mobile phone, to calculate
        the position and direction of the user against the audio source,
        creating an audio only augmentation delivering an experience akin to
        walking within the music.
      </p>
      <p>
        Our seamless UX provides effortless onboarding and allows users to
        easily calibrate their physical spaces up to a 10mx10m scale experience
        environment at home or at live music/festival environments. The
        application then maps the individual component tracks of a piece of
        music to within the calibrated space, mimicking how it was recorded in
        real life allowing you to explore each individual music stem.
      </p>
    </Text>
    <Video vimeoId="597443720" vimeoCode="4b0f8c5e68" />
    <Caption> Into The Light - Supercut </Caption>
    <Text title="Output" split={1} large>
      <p>
        Since it's inception, The Traverse Platform has featured in multiple
        festivals around the world including SXSW, Sonaar and Tribeca Film
        Festival delivering audio experiences that range from classical to rock
        n roll, featuring artists such as YoYo Ma, Elvis, Sougwen Chung, Gadi
        Sassoon and more.
      </p>
      <p>
        Spatial Audio is a significant step forward in how we all experience
        music / SFX ranging from home theatre to mobile, now more than ever with
        Apple's recent update to support spatial and Dolby Atmos.
      </p>
      <p>
        Traverse is a product that we continue to develop, aiming to support
        both the creation and deployment of unique audio-first, Augmented
        Reality experiences. If you are interested in testing our beta platform,
        please do contact us at hello@superbright.me and we can arrange access.
      </p>
    </Text>
    <Images>
      <Image src="../../images/work/traverse/IMG_4882.JPG" 
      alt="Traverse" />
      <Caption>SXSW 2019</Caption>
      
      <Image
        src="../../images/work/traverse/traverse-instruments.jpg"
        alt="traverse"
      />
      <Caption>Interactive Sculptures from 'Chaos & Order'</Caption>
      
      <Image
        src="../../images/work/traverse/traverse-instruments-detail-2.jpg"
        alt="traverse"
      />
      <Caption>Interactive Sculptures from 'Chaos & Order'</Caption>
      
      <Image
        src="../../images/work/traverse/traverse-instruments-detail-1.jpg"
        alt="traverse"
      />
      <Caption>Interactive Sculptures from 'Chaos & Order'</Caption>
    </Images>
    <Press
      quotes={[
        {
          quote: '2019 Virtual Cinema Jury Award Winner',
          heading: 'SXSW Film Festival',
          url: 'https://www.sxsw.com/film/2019/the-2019-sxsw-film-festival-announces-audience-award-winners/',
          date: 'March, 2019'
        },
        {
          quote:
            'Spatial Audio App Traverse to Launch at SXSW With Immersive Elvis Experience',
          heading: 'Variety',
          url: 'https://variety.com/2019/digital/news/spatial-audio-platform-traverse-sxsw-elvis-1203151221/',
          date: 'February, 2019'
        },
        {
          quote: 'Virtual Cinema Jury Award Winner',
          heading: 'SXSW',
          url: 'https://www.sxsw.com/film/2019/the-2019-sxsw-film-festival-announces-audience-award-winners/',
          date: 'March, 2019'
        },
        {
          quote: 'Film Festivals Move to Make Virtual Reality a Marquee Event',
          heading: 'WIRED',
          url: 'https://www.wired.com/story/film-festivals-move-to-make-vr-marquee-events/',
          date: 'April, 2019'
        }
      ]}
    />
    <Credits credits={frontmatter.credits} />
  </Layout>
);

export default Page;
